import React from 'react'
import { Translation } from 'react-i18next'

export const PRODUCT_DATABASE_TABLE_NAMES = {
  productTitle: (
    <Translation ns="common">
      {t => t('common:MetricName.productTitle', 'Product Title')}
    </Translation>
  ),
  category: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.category', 'Category')}</span>}
    </Translation>
  ),
  variantDifferences: (
    <Translation ns="common">
      {t => (
        <span>
          {t('common:MetricName.variantDifferences', 'Variant Differences')}
        </span>
      )}
    </Translation>
  ),
  monthlyRevenue: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.revenue', 'Revenue')}</span>}
    </Translation>
  ),
  monthlySales: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.unitsSold', 'Units Sold')}</span>}
    </Translation>
  ),
  price: (
    <Translation ns="common">
      {t => t('common:MetricName.price', 'Price')}
    </Translation>
  ),
  rank: (
    <Translation ns="common">
      {t => t('common:MetricName.BSR', 'BSR')}
    </Translation>
  ),
  reviews: (
    <Translation ns="common">
      {t => t('common:MetricName.reviews', 'Reviews')}
    </Translation>
  ),
  lqs: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.LQS', 'LQS')}</span>}
    </Translation>
  ),
  sellers: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.sellers', 'Sellers')}</span>}
    </Translation>
  ),
  listedAt: (
    <Translation ns="common">
      {t => (
        <span>
          {t('common:MetricName.dateFirstAvailable', 'Date First Available')}
        </span>
      )}
    </Translation>
  ),
  rating: (
    <Translation ns="common">
      {t => t('common:MetricName.starRating', 'Star Rating')}
    </Translation>
  ),
  dimensions: (
    <Translation ns="common">
      {t => t('common:MetricName.dimensionsLWH', 'Dimensions (LxWxH)')}
    </Translation>
  ),
  weight: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.weight', 'Weight')}</span>}
    </Translation>
  ),
  scrapedAt: (
    <Translation ns="common">
      {t => <span>{t('common:MetricName.lastUpdated', 'Last Updated')}</span>}
    </Translation>
  )
}

export const PRODUCT_DATABASE_TABLE_SUBTITLES = {
  productTitle: (
    <Translation ns="common">
      {t => t('common:MetricName.asin&Brand', 'ASIN | Brand')}
    </Translation>
  ),
  monthlyRevenue: (
    <Translation ns="generic">
      {t => <span>{t('generic:Monthly', 'Monthly')}</span>}
    </Translation>
  ),
  monthlySales: (
    <Translation ns="generic">
      {t => <span>{t('generic:Monthly', 'Monthly')}</span>}
    </Translation>
  ),
  price: (
    <Translation ns="common">
      {t => t('common:MetricName.net&Fees', '(Net | Fees)')}
    </Translation>
  ),
  sellers: (
    <Translation ns="common">
      {t => t('common:MetricName.buyBoxOwner', 'Buy Box Owner')}
    </Translation>
  ),
  dimensions: (
    <Translation ns="common">
      {t => t('common:MetricName.tier', 'Tier')}
    </Translation>
  )
}

export const PRODUCT_DATABASE_TABLE_TOOLTIPS = {
  category: (
    <Translation ns="common">
      {t =>
        t(
          'common:MetricTooltip.category',
          'The Amazon category where this product is listed.'
        )
      }
    </Translation>
  ),
  variantDifferences: (
    <Translation ns="common">
      {t =>
        t(
          'common:MetricTooltip.variantDifferences',
          'How child ASINs of a parent ASIN are distinguished from each other (i.e. color, size, etc.)'
        )
      }
    </Translation>
  ),
  monthlyRevenue: (
    <Translation ns="common">
      {t =>
        t(
          'common:MetricTooltip.revenue',
          'The estimated monthly revenue generated from product sales.'
        )
      }
    </Translation>
  ),
  monthlySales: (
    <Translation ns="common">
      {t =>
        t(
          'common:MetricTooltip.unitsSold',
          'The estimated monthly units sold based on BSR.'
        )
      }
    </Translation>
  ),
  price: (
    <Translation ns="common">
      {t =>
        t('common:MetricTooltip.price', 'The sales price of the product sold.')
      }
    </Translation>
  ),
  rank: (
    <Translation ns="common">
      {t =>
        t(
          'common:MetricTooltip.BSR',
          'Best Seller Rank (BSR) is an indicator of how well a product is selling compared to other products in the same category. The lower the number, the higher this product listing appears in the search results page on Amazon.'
        )
      }
    </Translation>
  ),
  reviews: (
    <Translation ns="common">
      {t =>
        t(
          'common:MetricTooltip.reviews',
          'Number of reviews, which includes both standalone star ratings and those which include written reviews and/or photos.'
        )
      }
    </Translation>
  ),
  lqs: (
    <Translation ns="common">
      {t =>
        t(
          'common:MetricTooltip.LQS',
          'The Listing Quality Score (LQS) assigned by Jungle Scout. 1 indicates a poor quality listing while 10 indicates a high quality listing.'
        )
      }
    </Translation>
  ),
  sellers: (
    <Translation ns="common">
      {t =>
        t(
          'common:MetricTooltip.sellers',
          'The number of sellers on a given listing (not overall on Amazon).'
        )
      }
    </Translation>
  ),
  listedAt: (
    <Translation ns="common">
      {t =>
        t(
          'common:MetricTooltip.dateFirstAvailable',
          'The date a product listing first went live on Amazon. Helpful for gauging sales history and how established a product might be.'
        )
      }
    </Translation>
  ),
  rating: (
    <Translation ns="common">
      {t =>
        t('common:MetricTooltip.starRating', 'The star rating shown on Amazon.')
      }
    </Translation>
  ),
  dimensions: (
    <Translation ns="common">
      {t =>
        t(
          'common:MetricTooltip.dimensionsLWH',
          'The length, width, and height of 1 unit (not Amazon shipping box dimensions).'
        )
      }
    </Translation>
  ),
  weight: (
    <Translation ns="common">
      {t =>
        t('common:MetricTooltip.weight', 'The weight of the product in lbs.')
      }
    </Translation>
  )
}
