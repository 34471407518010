export const CSV_EXPORT_VARIANTS_LIMIT = 5

export const PRODUCT_DATABASE_JUNGLE_SCOUT_PRESETS = {
  'High Demand + Low Competition': {
    label: t => {
      return t(
        'common:LoadPreset.jsPreset1Name',
        'High Demand + Low Competition'
      )
    },
    desc: t => {
      return t(
        'common:LoadPreset.jsPreset1Desc',
        'Look for products that are selling well, but have a low number of reviews - a sign the product isn’t full of competitors.'
      )
    }
  },
  'High Demand + Bad Marketing': {
    label: t => {
      return t('common:LoadPreset.jsPreset2Name', 'High Demand + Bad Marketing')
    },
    desc: t => {
      return t(
        'common:LoadPreset.jsPreset2Desc',
        'Look for products that are selling well, but have poor listings. You can swoop in and use your marketing genius!'
      )
    }
  },
  'Good Return on Investment': {
    label: t => {
      return t('common:LoadPreset.jsPreset3Name', 'Good Return on Investment')
    },
    desc: t => {
      return t(
        'common:LoadPreset.jsPreset3Desc',
        'It would be a shame to pick a great product, only to have your profits eaten up by fees. Look for products with good net earning potential!'
      )
    }
  },
  'Good Demand + Poor Quality Product': {
    label: t => {
      return t(
        'common:LoadPreset.jsPreset4Name',
        'Good Demand + Poor Quality Product'
      )
    },
    desc: t => {
      return t(
        'common:LoadPreset.jsPreset4Desc',
        'You can improve on existing products by hunting down opportunities that are selling well, but customers aren’t reviewing well! HINT: Read some reviews and identify areas for improvement when you manufacture your product.'
      )
    }
  }
}
