import React from 'react'

import { sizes } from 'ui_elements/TableV2/styles'
import { VariantDifferences } from 'ui_elements/VariantDifferences/VariantDifferences'
import { LoadingPlaceholderGradient } from '@junglescout/edna'

import { ReviewsCell } from 'src/js/components/product_database/ProductDatabaseTableV2/ReviewsCell'
import { RatingCell } from 'src/js/components/product_database/ProductDatabaseTableV2/RatingCell'
import { SellersCell } from 'src/js/components/product_database/ProductDatabaseTableV2/SellersCell'
import { ListedAtCell } from 'src/js/components/product_database/ProductDatabaseTableV2/ListedAtCell'
import { DimensionsCell } from 'src/js/components/product_database/ProductDatabaseTableV2/DimensionsCell'
import { MonthlyRevenueCell } from 'src/js/components/product_database/ProductDatabaseTableV2/MonthlyRevenueCell'
import { ProductTitleCell } from 'src/js/components/product_database/ProductDatabaseTableV2/ProductTitleCell'
import { PriceCell } from 'src/js/components/product_database/ProductDatabaseTableV2/PriceCell'
import { SharedBsrTooltip } from 'src/js/components/product_database/ProductDatabaseTableV2/SharedBsrTooltip'
import { LastUpdatedCell } from 'src/js/components/product_database/ProductDatabaseTableV2/LastUpdatedCell'

import { RESULTS_DATA_ATTRIBUTE_IDS } from 'constants/data_attributes/product_database/results'

import {
  formatProductEstimatedSales,
  formatProductListingQualityScore,
  formatProductRank,
  formatProductWeight
} from 'helpers/product_database/formatters'
import { parseVariantProductDifferences } from 'helpers/product'

import {
  PRODUCT_DATABASE_TABLE_NAMES,
  PRODUCT_DATABASE_TABLE_SUBTITLES,
  PRODUCT_DATABASE_TABLE_TOOLTIPS
} from './display_names'

const renderLoading = () => {
  return (
    <>
      <LoadingPlaceholderGradient width="60%" height="5px" />
      <LoadingPlaceholderGradient width="80%" height="5px" />
    </>
  )
}

export const PRODUCT_DATABASE_TABLE_V2_COLUMNS = [
  {
    index: 0,
    position: 0,
    accessor: 'name',
    Header: PRODUCT_DATABASE_TABLE_NAMES.productTitle,
    subHeader: PRODUCT_DATABASE_TABLE_SUBTITLES.productTitle,
    sortable: true,
    width: 400,
    defaultSelection: true,
    size: sizes['xx-large'],
    dataId: RESULTS_DATA_ATTRIBUTE_IDS.SortColumns.ProductTitle,
    Cell: data => {
      const depth = data?.row?.depth
      const original = data?.row?.original

      return <ProductTitleCell product={original} isVariant={depth > 0} />
    }
  },
  {
    index: 1,
    position: 1,
    accessor: 'variantDifferences',
    Header: PRODUCT_DATABASE_TABLE_NAMES.variantDifferences,
    headerTooltip: PRODUCT_DATABASE_TABLE_TOOLTIPS.variantDifferences,
    defaultSelection: true,
    sortable: false,
    width: sizes.large,
    type: 'text',
    dataId: RESULTS_DATA_ATTRIBUTE_IDS.SortColumns.VariantDifferences,
    Cell: data => {
      const row = data?.row
      const original = row?.original

      if (original?.isLoading) {
        return renderLoading()
      }

      if (row.depth > 0) {
        const fullList = parseVariantProductDifferences(original)
        return <VariantDifferences productLevel="variant" fullList={fullList} />
      }

      return (
        <VariantDifferences
          productLevel="parent"
          fullList={original?.dimensions}
        />
      )
    }
  },
  {
    index: 2,
    position: 2,
    accessor: 'calculatedCategory',
    Header: PRODUCT_DATABASE_TABLE_NAMES.category,
    headerTooltip: PRODUCT_DATABASE_TABLE_TOOLTIPS.category,
    sortable: true,
    width: sizes.large,
    defaultSelection: true,
    dataId: RESULTS_DATA_ATTRIBUTE_IDS.SortColumns.Category,
    Cell: data => {
      const row = data?.row
      const original = row?.original

      if (original?.isLoading) {
        return renderLoading()
      }

      return original?.subCategory || original?.calculatedCategory
    }
  },
  {
    index: 3,
    position: 3,
    accessor: 'estRevenue',
    Header: PRODUCT_DATABASE_TABLE_NAMES.monthlyRevenue,
    subHeader: PRODUCT_DATABASE_TABLE_SUBTITLES.monthlyRevenue,
    headerTooltip: PRODUCT_DATABASE_TABLE_TOOLTIPS.monthlyRevenue,
    sortable: true,
    defaultSelection: true,
    type: 'number',
    dataId: RESULTS_DATA_ATTRIBUTE_IDS.SortColumns.MonthlyRevenue,
    Cell: data => {
      const row = data?.row
      const original = row?.original

      if (original?.isLoading) {
        return renderLoading()
      }

      if (row.depth > 0 && original.isSharedBSR) {
        return <SharedBsrTooltip />
      }

      return <MonthlyRevenueCell product={original} />
    }
  },
  {
    index: 4,
    position: 4,
    accessor: 'estimatedSales',
    Header: PRODUCT_DATABASE_TABLE_NAMES.monthlySales,
    subHeader: PRODUCT_DATABASE_TABLE_SUBTITLES.monthlySales,
    headerTooltip: PRODUCT_DATABASE_TABLE_TOOLTIPS.monthlySales,
    sortable: true,
    defaultSelection: true,
    type: 'number',
    dataId: RESULTS_DATA_ATTRIBUTE_IDS.SortColumns.MonthlySales,
    Cell: data => {
      const row = data?.row
      const original = row?.original

      if (original?.isLoading) {
        return renderLoading()
      }

      if (row.depth > 0 && original.isSharedBSR) {
        return <SharedBsrTooltip />
      }

      return formatProductEstimatedSales(original?.estimatedSales, original)
    }
  },
  {
    index: 5,
    position: 5,
    accessor: 'price',
    Header: PRODUCT_DATABASE_TABLE_NAMES.price,
    subHeader: PRODUCT_DATABASE_TABLE_SUBTITLES.price,
    headerTooltip: PRODUCT_DATABASE_TABLE_TOOLTIPS.price,
    sortable: true,
    defaultSelection: true,
    type: 'number',
    width: 200,
    dataId: RESULTS_DATA_ATTRIBUTE_IDS.SortColumns.Price,
    Cell: data => {
      const original = data?.row?.original
      if (original?.isLoading) {
        return renderLoading()
      }
      return <PriceCell product={original} />
    }
  },
  {
    index: 6,
    position: 6,
    accessor: 'rank',
    Header: PRODUCT_DATABASE_TABLE_NAMES.rank,
    headerTooltip: PRODUCT_DATABASE_TABLE_TOOLTIPS.rank,
    sortable: true,
    defaultSelection: true,
    type: 'number',
    dataId: RESULTS_DATA_ATTRIBUTE_IDS.SortColumns.Rank,
    Cell: data => {
      const original = data?.row?.original
      if (original?.isLoading) {
        return renderLoading()
      }
      return formatProductRank(original.rank, original)
    }
  },
  {
    index: 7,
    position: 7,
    accessor: 'nReviews',
    Header: PRODUCT_DATABASE_TABLE_NAMES.reviews,
    headerTooltip: PRODUCT_DATABASE_TABLE_TOOLTIPS.reviews,
    sortable: true,
    defaultSelection: true,
    width: sizes.medium,
    type: 'number',
    dataId: RESULTS_DATA_ATTRIBUTE_IDS.SortColumns.Reviews,
    Cell: data => {
      const original = data?.row?.original
      if (original?.isLoading) {
        return renderLoading()
      }
      return <ReviewsCell product={original} />
    }
  },
  {
    index: 8,
    position: 8,
    accessor: 'listingQualityScore',
    Header: PRODUCT_DATABASE_TABLE_NAMES.lqs,
    headerTooltip: PRODUCT_DATABASE_TABLE_TOOLTIPS.lqs,
    sortable: true,
    defaultSelection: true,
    type: 'number',
    dataId: RESULTS_DATA_ATTRIBUTE_IDS.SortColumns.LQS,
    Cell: data => {
      const original = data?.row?.original
      if (original?.isLoading) {
        return renderLoading()
      }
      return formatProductListingQualityScore(original?.listingQualityScore)
    }
  },
  {
    index: 9,
    position: 9,
    accessor: 'nSellers',
    Header: PRODUCT_DATABASE_TABLE_NAMES.sellers,
    subHeader: PRODUCT_DATABASE_TABLE_SUBTITLES.sellers,
    headerTooltip: PRODUCT_DATABASE_TABLE_TOOLTIPS.sellers,
    sortable: true,
    defaultSelection: true,
    type: 'number',
    dataId: RESULTS_DATA_ATTRIBUTE_IDS.SortColumns.Sellers,
    Cell: data => {
      const original = data?.row?.original
      if (original?.isLoading) {
        return renderLoading()
      }
      return <SellersCell product={original} />
    }
  },
  {
    index: 10,
    position: 10,
    accessor: 'listedAt',
    Header: PRODUCT_DATABASE_TABLE_NAMES.listedAt,
    headerTooltip: PRODUCT_DATABASE_TABLE_TOOLTIPS.listedAt,
    sortable: true,
    defaultSelection: true,
    type: 'number',
    dataId: RESULTS_DATA_ATTRIBUTE_IDS.SortColumns.DateFirstAvailable,
    Cell: data => {
      const original = data?.row?.original
      if (original?.isLoading) {
        return renderLoading()
      }
      return <ListedAtCell product={original} />
    }
  },
  {
    index: 11,
    position: 11,
    accessor: 'rating',
    Header: PRODUCT_DATABASE_TABLE_NAMES.rating,
    headerTooltip: PRODUCT_DATABASE_TABLE_TOOLTIPS.rating,
    sortable: true,
    defaultSelection: true,
    type: 'number',
    dataId: RESULTS_DATA_ATTRIBUTE_IDS.SortColumns.Rating,
    Cell: data => {
      const original = data?.row?.original
      if (original?.isLoading) {
        return renderLoading()
      }
      return <RatingCell product={original} />
    }
  },
  {
    index: 12,
    position: 12,
    accessor: 'length',
    Header: PRODUCT_DATABASE_TABLE_NAMES.dimensions,
    subHeader: PRODUCT_DATABASE_TABLE_SUBTITLES.dimensions,
    headerTooltip: PRODUCT_DATABASE_TABLE_TOOLTIPS.dimensions,
    sortable: true,
    defaultSelection: true,
    width: sizes['x-large'],
    type: 'number',
    dataId: RESULTS_DATA_ATTRIBUTE_IDS.SortColumns.Dimensions,
    Cell: data => {
      const original = data?.row?.original
      if (original?.isLoading) {
        return renderLoading()
      }
      return <DimensionsCell product={original} />
    }
  },
  {
    index: 13,
    position: 13,
    accessor: 'weight',
    Header: PRODUCT_DATABASE_TABLE_NAMES.weight,
    headerTooltip: PRODUCT_DATABASE_TABLE_TOOLTIPS.weight,
    sortable: true,
    defaultSelection: true,
    type: 'number',
    dataId: RESULTS_DATA_ATTRIBUTE_IDS.SortColumns.Weight,
    Cell: data => {
      const original = data?.row?.original
      if (original?.isLoading) {
        return renderLoading()
      }
      return formatProductWeight(original?.weight, original)
    }
  },
  {
    index: 14,
    position: 14,
    accessor: 'scrapedAt',
    Header: PRODUCT_DATABASE_TABLE_NAMES.scrapedAt,
    sortable: true,
    defaultSelection: true,
    Cell: data => {
      const original = data?.row?.original
      if (original?.isLoading) {
        return renderLoading()
      }
      return <LastUpdatedCell product={original} />
    }
  }
]
